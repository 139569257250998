<template>
    <div>
        <div class="d-flex justify-content-between">
            <div>
                <div class="heading-text">Invoice Details</div>
                <p class="text-simple">{{invoiceDetails.description}}</p>
            </div>
            <div v-if="invoiceDetails.status=='draft'">
                <b-button variant="primary" :disabled="sendLoading" @click="()=>$emit('send')">Send</b-button>
            </div>
        </div>
        <div class="border-top mt-1 m-0 py-1 text-simple fw-normal d-flex justify-content-between">
            <div>Amount</div>
            <div>{{invoiceDetails.amount | currency}}</div>
        </div>
        <div class="border-top  py-1 m-0 text-simple fw-normal d-flex justify-content-between">
            <div>Status</div>
            <b-badge :variant="invoiceDetails.status=='sent'?'light-success':invoiceDetails.status=='paid'?'light-primary': 'light-info'">
                {{ toCapitalize(invoiceDetails.status) }}
            </b-badge>
        </div>
        <div class="border-top  py-1 m-0 text-simple fw-normal d-flex justify-content-between align-items-center">
            <div>Customers</div>
            <b-avatar-group size="32px">
                <b-avatar
                :class="[invoiceDetails.collaborators.length<2?'mx-0':'']" v-for="(c,index) in invoiceDetails.collaborators.slice(0,2)" :key="index"
                :text="c.email.slice(0,2).toUpperCase()"
                :variant="colors[index]"
                v-b-tooltip.hover.v-primary="c.email"
              />
              <b-avatar v-if="invoiceDetails.collaborators.length > 2" 
                class="mx-0" 
                :text="`+${invoiceDetails.collaborators.length-2}`"
                variant="light-success"
                id="tooltip-avatar"
                v-b-tooltip.hover.v-primary="getcollaborators(invoiceDetails.collaborators.slice(2))"

                />
                <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in invoiceDetails.collaborators.slice(2)" :key="index">{{c.email}}<br></span></b-tooltip> -->
            </b-avatar-group>
        </div>
        <div class="border-top  py-1 mb-2 mt-0 text-simple fw-normal d-flex justify-content-between">
            <div>Due Date</div>
            <div>{{ getFormatedDate(invoiceDetails.dueDate,"DD MMM YYYY") }}</div>
        </div>
        <div class="mr-auto">
            <app-simple-button class="stick-bottom" variant="danger" v-if="invoiceDetails.status=='draft'" :loading="deleteLoading" @click="()=>$emit('delete',invoiceDetails._id)">Delete</app-simple-button>
            <app-simple-button class="stick-bottom mt-1" variant="primary" :loading="downloadLoading" @click="()=>$emit('download')">Download</app-simple-button>
            <!-- <app-simple-button class="stick-bottom mt-1" variant="outline-primary" :loading="backupLoading" @click="()=>$emit('backup')">Save to Backup</app-simple-button> -->
        </div>
    </div>
</template>
<script>
import {BBadge,BButton, BAvatarGroup, BAvatar, VBTooltip, BTooltip} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import moment from 'moment'

export default {
    directives: {
        'b-tooltip': VBTooltip,
    },
    components:{
        BBadge,
        BButton,
        BAvatarGroup, 
        BAvatar,
        BTooltip,
        AppSimpleButton
    },
    data() {
        return {
            invoiceDetails:{
                description:'',
                amount:0,
                status:'',
                collaborators:[],
                dueDate:'',
            },
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            sendLoading:true,
            deleteLoading:false,
            downloadLoading:false,
        }
    },
    methods: {
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        }
    },
}
</script>
<style>
</style>