<template>
    <b-card
      class="card-transaction"
      no-body
    >
    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
    >
      <b-card-header>
        <div>
          <b-card-title>Total Earnings</b-card-title>
          <div class="heading"> {{ totalEarnings | currency }}</div>
        </div>
        <!-- <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item href="#" @click="()=>type='Monthly'">
            Monthly
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="()=>type='Weekly'">
            Weekly
          </b-dropdown-item>
        </b-dropdown> -->
      </b-card-header>
  
      <b-card-body>
        <div>
            <vue-apex-charts 
            id="revenue-report-chart"
            type="bar"
            height="230"
            ref="earningChart"
            :options="chartOptions"
            :series="revenueReport.series"
            />
        </div>
        <div
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-primary"
              >
                <feather-icon
                  size="18"
                  color="primary"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="text-simple m-0">
                Total Sales
              </h6>
              <small>Refund</small>
            </b-media-body>
          </b-media>
          <div
            class="text-simple text-success font-arial"
           >
            +{{this.totalSales | currency}}
          </div>
        </div>
        <div
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-secondary"
              >
                <feather-icon
                  size="18"
                  color="secondary"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="text-simple m-0">
                Total Revenue
              </h6>
              <small>Client payment</small>
            </b-media-body>
          </b-media>
          <div
            class="text-simple text-success font-arial"
           >
            +{{this.totalExpense | currency}}
          </div>
        </div>
      </b-card-body>
    </b-overlay> 
    </b-card>
  </template>
  
  <script>
  import {
   BOverlay, BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import {get_earnings_report} from '@/apis/admin/dashboard'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      VueApexCharts,
      BOverlay
    },
    data(){
        return{
            chartOptions: {
                chart: {
                        stacked: true,
                        type: 'bar',
                        toolbar: { show: false },
                    },
                    grid: {
                        padding: {
                        top: -20,
                        bottom: -10,
                        },
                        yaxis: {
                        lines: { show: false },
                        },
                    },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                    labels: {
                    style: {
                        colors: '#6E6B7B',
                        fontSize: '0.86rem',
                        fontFamily: 'Montserrat',
                    },
                    show:false,
                    },
                    axisTicks: {
                    show: false,
                    },
                    axisBorder: {
                    show: false,
                    },
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [$themeColors.primary, $themeColors.light],
                plotOptions: {
                    bar: {
                    columnWidth: '17%',
                    endingShape: 'rounded',
                    },
                    distributed: true,
                },
                yaxis: {
                    labels: {
                    style: {
                        colors: '#6E6B7B',
                        fontSize: '0.86rem',
                        fontFamily: 'Montserrat',
                    },
                    show: false,
                    },
                },
            },
            earnings: [
                {
                    heading:'Total Sales',
                    sub_heading:'Refund',
                    icon:'DollarSignIcon',
                    color:'primary',
                    amount:'98'
                },
                {
                    heading:'Total Revenue',
                    sub_heading:'Client payment',
                    icon:'TrendingUpIcon',
                    color:'secondary',
                    amount:'216'
                }
            ],
            revenueReport: {
                series: [
                    {
                    name: 'Earning',
                    data: [],
                    },
                    {
                    name: 'Expense',
                    data: [],
                    },
                ],
            },
            totalEarnings:0,
            totalSales:0,
            totalRevenue:0,
        }
    },
    mounted(){
      this.getEarningsReport()
    },
    methods: {
      getEarningsReport(){
        get_earnings_report({type:'monthly'}).then(res=>{
          let earnings = res.earnings.map(item => item.earning)
          let expenses=res.earnings.map(item => item.expense>0?-item.expense:item.expense)
          this.$refs.earningChart.updateSeries([{data:earnings},{data:expenses}])
            this.totalEarnings=res.currentEarnings
            this.totalSales=res.currentEarnings
            // this.lastWeekEarnings=res.previousEarnings
            this.totalExpense=res.totalExpense
          }
        )
      }
    }
  }
</script>
<style scoped>
  .heading{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 38px;
   /*font-weight: 600;*/
   line-height: 52px;
   color: #4B465C;
  }
  .sub-heading{
    font-size: 18px;
   /*font-weight: 600;*/
   line-height: 24px;
   color: #4B465C;
  }
  .text-simple{
    font-size: 15px;
    /*font-weight: 600;*/
    line-height:21px;
    color: #6E6B7B;
  }
  .font-arial{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
</style> 
  