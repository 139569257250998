<template>
  <b-card
    class="card-transaction"
    no-body
  >
  <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
    >
    <b-card-header>
      <div>
        <b-card-title class="sub-heading" >Earning Reports</b-card-title>
        <b-card-text class="font-small-2">
            {{ type }} Earnings Overview
        </b-card-text>
      </div>

      <div>
        <b-dropdown
        :text="type"
        variant="outline-secondary"
      >
      <b-dropdown-item href="#" @click="()=>getMonthlyReport()">
        Monthly
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="()=>getWeeklyReport()">
        Weekly</b-dropdown-item>
      </b-dropdown>
      </div>
    </b-card-header>

    <b-card-body>
      <div class="row align-items-center">
        <div class="col-md-4 col-12">
          <div class="d-flex align-items-center">
            <div>
              <h1 class="heading-text">
                {{ totalEarnings| currency}}
              </h1>
            </div>
            <div>
              <b-badge class="d-inline-block ml-1" :variant="getPercentage>0?'light-success':'light-danger'">{{`${getPercentage>0?'+':''}${getPercentage.toFixed(2)}%`}}</b-badge> 
            </div>
            </div>
             <p class="text-simple">You earnings of this {{ type=='Weekly'?'week':'month' }} compared to last {{ type=='Weekly'?'week':'month' }}</p>
        </div>
        <div class="col-md-8 col-12">
          <vue-apex-charts
            v-show="totalEarnings>0"
            type="bar"
            height="200"
            :options="chartOptions"
            :series="getEarnings"
            ref="earningChart"
          />
        </div>
    </div>
    <div class="row border rounded py-1 mt-2">
      <div class="col-4">
          <div class="d-flex align-items-center">
            <b-avatar
              rounded
              size="30"
              :variant="`light-primary`"
            >
              <feather-icon
                size="18"
                color="primary"
                icon="DollarSignIcon"
              />
            </b-avatar>
            <span class="label-text">
              Earnings
            </span>
          </div>
            <div class="amount-text my-8">{{ totalEarnings | currency}}</div>
            <b-progress
              value="50"
              max="100"
              height="4px"
              variant="primary"
              />
      </div>
      <div class="col-4">
        <div class="d-flex align-items-center">
          <b-avatar
              rounded
              size="30"
              :variant="`light-info`"
            >
            <feather-icon
              size="18"
              color="info"
              icon="PieChartIcon"
            />
          </b-avatar>
          <span class="label-text">
            Profit
          </span>
        </div> 
        <div class="amount-text my-8">{{ totalEarnings- totalExpense>0?totalEarnings- totalExpense:0 | currency}}</div>
        <b-progress
          value="50"
          max="100"
          height="4px"
          variant="info"
          />
      </div>
      <div class="col-4">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="30"
            :variant="`light-danger`"
          >
            <feather-icon
              size="18"
              color="danger"
              icon="TrendingDownIcon"
            />
          </b-avatar>
          <span class="label-text">
            Expense
          </span>
        </div>
          <div class="amount-text my-8">{{totalExpense | currency }}</div>
          <b-progress
            value="50"
            max="100"
            height="4px"
            variant="danger"
            />
      </div>
  </div>
    </b-card-body>
  </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader,BBadge, BCardTitle,BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,BProgress
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import {get_earnings_report} from '@/apis/admin/dashboard'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
    BCardText,
    BProgress,
    BBadge,
    BOverlay
  },
  data() {
    return {
      salesBar: {
          series: [
              {
              name: 'Sessions',
              data: [20,54,22,544,23,23,23],
              },
          ],
      },
      chartOptions: {
        chart: {
          sparkline: { enabled: true },
          toolbar: { show: false },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: [
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
        ],
        plotOptions: {
          bar: {
            columnWidth: '80%',
            distributed: true,
            borderRadius:5,
            // endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'],
          labels: {
            show: true,
            rotate: -45,
            offsetX: -10,
          }
        },
      },
      earnings:[],
      totalEarnings:0,
      lastWeekEarnings:0,
      totalExpense:0,
      type:'Weekly',
      loading:false,
    }
  },
  computed: {
    getEarnings(){
      [{
        name: 'Values',
        data: this.earnings.map(item => item.earning)
      }];
    },
    getPercentage(){
      let earnings=this.totalEarnings-this.lastWeekEarnings
      return earnings>0?earnings/this.totalEarnings*100:0
    },

  },
  mounted() {
    this.getEarningsReport()
  },
  watch:{
    type(){
      if(this.type=='Weekly'){
        this.$refs.earningChart.updateOptions({
          xaxis: {
            categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',]
          }
        })
        }else{
          this.$refs.earningChart.updateOptions({
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            }
          })
      }
    }
  },
  methods:{
    getEarningsReport(){
      this.loading=true
      get_earnings_report({type:this.type.toLowerCase()}).then(res=>{
        this.loading=false
          this.earnings = res.earnings.map(item => item.earning)
          this.$refs.earningChart.updateSeries([{name:'Earnings',data:this.earnings}])
          this.totalEarnings=res.currentEarnings
          this.lastWeekEarnings=res.previousEarnings
          this.totalExpense=res.totalExpense
        
        }
      ).catch(err=>{
        this.loading=false
      })
    },
    getMonthlyReport(){
        this.type='Monthly'
        this.getEarningsReport()
      },
      getWeeklyReport(){
        this.type='Weekly'
        this.getEarningsReport()
      },
  }
}
</script>
<style scoped>
.heading-text{
  font-size: 38px;
  /*font-weight: 600;*/
  line-height:52px;
  color: #4B465C;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.text-simple{
  font-size: 13px;
  font-weight: 400;
  line-height:20px;
  color: #6E6B7B;
}
.sub-heading{
  font-size: 18px;
 /*font-weight: 600;*/
 line-height: 24px;
 color: #4B465C;
}
.amount-text{
  font-size: 22px;
  /*font-weight: 600;*/
  line-height: 30px;
  color: #4B465C;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.label-text{
  font-size: 15px;
  /*font-weight: 600;*/
  line-height: 22px;
  color: #4B465C;
   margin:0px 6px;
 }
 .my-8{
  margin: 8px 0px;
 }
</style>
