<template>
    <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-small
        :spinner-variant="variant"
        class="d-inline-block w-100"
    >
        <b-button
            :variant="variant"
            block
            class="mb-0"
            :disabled="disabled"
            @click="$emit('click')"
            
            >
        <slot></slot>
    </b-button>
</b-overlay>
</template>
<script>
import {BOverlay, BButton} from "bootstrap-vue"
export default {
    components:{
        BOverlay,
        BButton
    },
    props:{
        loading:[Boolean],
        variant:[String],
        disabled:{
            type:[Boolean],
            default:false
        }
    },
    data(){
        return {

        }
    }
}
</script>
