import { render, staticRenderFns } from "./SignDocument.vue?vue&type=template&id=0c32dccb&scoped=true"
import script from "./SignDocument.vue?vue&type=script&lang=js"
export * from "./SignDocument.vue?vue&type=script&lang=js"
import style0 from "./SignDocument.vue?vue&type=style&index=0&id=0c32dccb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c32dccb",
  null
  
)

export default component.exports