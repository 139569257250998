<template>
    <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5" v-show="currentStep==1">
            <confirm-payment  @prev="()=>this.$router.push('/invoices')" @next="next()"/>
        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-5" v-show="currentStep==2">
            <payment-details ref="paymentDetails" @prev="prev()" @next="payInvoice"/>
        </div>
    </div>
</template>
<script>
import ConfirmPayment from '@/components/functional-components/other/invoice/ConfirmPayment.vue';
import PaymentDetails from '@/components/functional-components/other/invoice/PaymentDetails.vue';
import {pay_invoice} from '@/apis/others/payments'
export default{
    components: {
        PaymentDetails,
        ConfirmPayment
    },
    data(){
        return{
            currentStep: 1,
            paymentMethodId:''
        }
    },
    methods:{
        next(){
            this.currentStep+=1;
        },
        prev(){
            this.currentStep-=1;
        },
        payInvoice(id){
            this.$refs.paymentDetails.loading=true;
            let details={
                invoiceId:this.$route.query.invoice_id,
                invoiceAmountPercentage:this.$route.query.type=='initial'?25:this.$route.query.type=='remaining'?75:100,
                paymentType:this.$route.query.type,
                paymentMethodId:id,
            }
            pay_invoice(details).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                })
                setTimeout(() => {
                        this.$router.push('/invoices')
                    },[1000]);
            }).catch(err=>{
                this.$refs.paymentDetails.loading=false;
                this.$bvToast.toast(err.message, {
                    title: err.error,
                    variant: 'danger',
                    solid: true,
                })
            })
        }
    },

}

</script>
<style>
</style>