<template>
    <div>
        <b-modal ref="createModal" v-model="show" size="md" centered hide-header hide-footer hide-header-close :no-close-on-backdrop="loading">
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Update Project Details</app-heading-1>
                </div>
                <div class="my-2">
                    <div class="row">
                        <div class="col-12">
                            <app-text-input label="Project name " v-model="folder_data.name" placeholder="Enter Name"></app-text-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-amount-input label="Project Amount" v-model="folder_data.estimatedProjectAmount"  placeholder="0.00"></app-amount-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-label>Scope of work</app-label>
                            <b-form-textarea
                                rows="4"
                                v-model="folder_data.scopeOfWork"
                                :maxLength="800"
                                :state="folder_data.scopeOfWork.length>800?false:null"
                            ></b-form-textarea>
                            <small class="text-danger" v-if="folder_data.scopeOfWork.length>800">Scope should be upto 800 charaters long</small>
                        </div>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" :disabled="loading" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading" :disabled="!folder_data.name.length"  @click="update()">Update</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import AppAmountInput from "@/components/app-components/AppAmountInput"
import AppLabel from "@/components/app-components/AppLabel"
import { update_project_details} from "@/apis/others/documents"
import { BModal, VBModal, BFormTextarea } from 'bootstrap-vue'
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSimpleButton,
        BModal,
        VBModal,
        BFormTextarea,
        AppNormalText,
        AppAmountInput,
        AppLabel
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            folder_data:{
                name:'',
                estimatedProjectAmount:null,
                scopeOfWork:'',
                id:null
            },
            projectId:null,
            loading:false,
        }
    },
    methods:{
        showModal(data){
            this.show = true

            this.folder_data = {...data, scopeOfWork:data.scopeOfWork?data.scopeOfWork:''}
        },
        hideModal(){
            this.show = false
        },
        update(){
            this.loading = true
            update_project_details({id:this.folder_data.id,data:this.folder_data}).then(resp=>{
                this.loading = false
                this.hideModal()
                this.$emit('onUpdated')
            }).catch(err=>{
                this.loading = false
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                    autoClose: 3000,
                })
            })
        }
    },
}
</script>
<style>
.sugestion-list{
    max-height: 100px;
    width: 95%;
    border:1px solid #ecf2ff;
    overflow-y: auto;
    margin-bottom: 8px;
    z-index: 1000;
    position:absolute;
    background-color: #ffffff;
}
.suggestion{
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.suggestion:hover{
    background-color: #ecf2ff;
}

.sugestion-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sugestion-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(226, 225, 225);
    border-radius: 10px;
  }
  
  /* Handle */
  .sugestion-list::-webkit-scrollbar-thumb {
    background: #ecf2ff;
    border-radius: 5px;
  }
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
</style>