<template>
    <div class="mb-3">
        <div class="bg-white rounded p-24" v-if="getUserRole=='service_provider'">
            <div class="text-bold">Current Plan</div>
            <div class="d-md-flex my-1">
                <div class="w-md-50 w-100">
                    <div class="mb-1">
                        <div class="text-simple">Your Current Plan is <b-badge variant="light-primary">{{subscription.name}}</b-badge></div>
                    </div>
                    <div class="mb-1" v-if="subscription.name!='Free'">
                        <div class="text-simple">Active until {{getFormatedDate(subscription.subscriptionEndDate,"MMM, DD YYYY")}}</div>
                        <div class="text-small">We will send you a notification upon Subscription expiration</div>
                    </div>
                    <div>
                        <b-button variant="primary" @click="$emit('onTabChange')">Change Plan</b-button>
                        <!-- <button class="btn bg-light-secondary text-secondary fw-bolder ml-2 confirm-btn" @click="()=>show=false">Cancel</button> -->
                    </div>
                    <!-- <div class="mb-1">
                        <div class="text-simple">$199 Per Month <b-badge variant="light-primary" class="ml-1">Popular</b-badge></div>
                        <div class="text-small">Standard plan for small to medium businesses</div>
                    </div> -->
                </div>
                <div class="w-md-50 w-100" v-if="subscription.name!='Free'">
                    <div class="bg-light-warning text-warning p-1 rounded mb-1" v-if="30-Number(getDiffernce(subscription.subscriptionStartDate))<10">
                        <div class="text-bold text-warning">We need your attention!</div>
                        <div class="text-simple text-warning">Your plan requires update</div>
                    </div>
                    <div class="my-1">
                        <div class="text-simple d-flex justify-content-between mb-6"><span>Days</span> <span class="ml-auto">{{ Number(getDiffernce(subscription.subscriptionStartDate))+1>30?30:Number(getDiffernce(subscription.subscriptionStartDate))+1 }} of 30 Days</span></div>
                        <b-progress :value="getPercentage(subscription.subscriptionStartDate)" variant="primary" class="mb-6"></b-progress>
                        <div class="text-small">{{ (30-Number(getDiffernce(subscription.subscriptionStartDate))-1)<0?0:(30-Number(getDiffernce(subscription.subscriptionStartDate))-1) }} days remaining until your plan requires update</div>
                    </div>
                </div>

            </div>
            
        </div>

        <div class="bg-white rounded p-24 border-0 my-2">
            <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-variant="primary"
                >
                    <div class="text-bold">Payment Methods</div>
                    <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
                    <div class="row" v-show="!newPayment">
                        <div class="col-md-6 col-12 mt-2 mb-1">
                            <div class="d-flex">
                                <img :src="getBrandIcon(cardData.brand)" alt="" height="25" width="45">
                                <app-heading-3 class="ml-1">....{{cardData.last4}}( expire {{cardData.exp_month}}/{{ cardData.exp_year }})</app-heading-3>
                                <div class="d-flex ml-auto cursor-pointer del-btn" @click="deletePaymentMethod()">
                                    <feather-icon icon="TrashIcon" class="text-danger m-right-4" size="22"></feather-icon>
                                    <app-heading-3> Remove</app-heading-3>
                                </div> 
                            </div>
                            <div class="mt-1 d-flex align-items-center cursor-pointer update-btn" @click="updatePaymentMethod()">
                                <div class="bg-primary rounded p-1 mr-1 ">
                                    <feather-icon icon="Edit3Icon" class="text-white" size="18"></feather-icon>
                                </div>
                                <app-heading-3> Update payment method</app-heading-3>
                            </div>
                        </div>
                    </div>
                    <div v-show="newPayment">
                        <validation-observer ref="paymentForm" >
                            <div class="col-md-6 col-12 my-1" >
                            
                                <div class="row ">
                                    <div class="col-12 mb-1 pl-0">
                                        <app-label>Card Number</app-label>
                                        <div class="input-field" :class="[cardNumberError?'border-danger':'']">
                                            <div ref="cardNumber"></div>
                                        </div>
                                        <small class="text-danger" v-if="cardNumberError.length>0" >{{ cardNumberError}}</small>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-12 col-lg-4 pl-0">
                                        <validation-provider #default="{ errors }" name="Name" vid="name" rules="required">
                                            <app-text-input label="Name" v-model="cardName" :errors="errors" placeholder="John Doe" @onKeydown="isLetter"/>
                                        </validation-provider>
                                    </div>
                                    <div class="col-6 col-lg-4 pl-0">
                                        <app-label>Expiration Date</app-label>
                                        <div class="input-field" :class="[expError?'border-danger':'']">

                                            <div ref="expryDate"></div>
                                        </div>
                                        <small class="text-danger" v-if="expError.length>0" >{{ expError}}</small>
                                    </div>
                                    <div class="col-6 col-lg-4 pl-0">
                                        <app-label>CVC</app-label>
                                        <div class="input-field" :class="[cvcError?'border-danger':'']">
                                            <div ref="cvc"></div>
                                        </div>
                                        <small class="text-danger" v-if="cvcError.length>0" >{{ cvcError}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="text-bold">Billing Address</div>
                            <div class="row mt-1">
                                <div class="col-md-6 col-12">
                                    <app-select-box label="Country" v-model="billing_details.country" disabled :options="options" @onChange="setStates()"></app-select-box>
                                </div>
                                <div class="col-md-6 col-12">
                                    <validation-provider #default="{ errors }" name="State" vid="state" rules="required">
                                        <app-select-box label="State" v-model="billing_details.state" :options="states" :errors="errors" @onChange="setCity()"></app-select-box>
                                    </validation-provider>
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <validation-provider #default="{ errors }" name="Address line 1" vid="address_line_1" rules="required">
                                        <app-text-input label="Address line 1" v-model="billing_details.line1" :errors="errors" placeholder="Address Line 1"/>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6 col-12">
                                    <app-text-input label="Address line 2" v-model="billing_details.line2" placeholder="Address Line 2"/>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <validation-provider #default="{ errors }" name="City" vid="city" rules="required">
                                        <app-text-input label="City" v-model="billing_details.city" placeholder="City" :errors="errors"/>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6 col-12">
                                    <validation-provider #default="{ errors }" name="Zip Code" vid="zip_code" rules="required">
                                        <app-text-input label="Zip Code" v-model="billing_details.postal_code" placeholder="63854" :errors="errors" @onKeydown="isZipCode"/>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <b-button variant="primary" @click="validatePaymentData()">Save Changes</b-button>
                                    <button class="btn bg-light-secondary text-secondary fw-bolder ml-2 confirm-btn" v-if="update" @click="()=>newPayment=false">Discard</button>
                                </div>
                            </div>
                        </validation-observer>
                    </div>
                </b-overlay>
            </div>
            <div class="bg-white rounded p-24 border-0 my-2" v-if="getUserRole=='service_provider'">
                <b-overlay
                    :show="bankAccountLoading"
                    rounded
                    opacity="0.6"
                    spinner-variant="primary"
                    >
                        <div class="text-bold">Connect Bank Account</div>
                        <div class="text-simple mt-1">Connect your bank account for receiving payments</div>
                        <div class="row">
                            <div class="col-md-6 col-12  mb-1">
                                <div class="d-flex justify-content-between mt-1 align-items-center" v-if="bankAccounts.length">
                                    <app-heading-3 class=""><b>Connected Account</b><br>{{ bankAccounts[0].account_holder_name?bankAccounts[0].account_holder_name:bankAccounts[0].bank_name }} (....{{bankAccounts[0].last4}})</app-heading-3>
                                    <feather-icon icon="Edit3Icon" class="text-primary cursor-pointer" size="18" @click="connectBankAccount"></feather-icon>

                                </div>
                                    <b-button variant="primary" class="mt-1" @click="connectBankAccount" v-else>
                                        <feather-icon icon="LinkIcon" class="text-white" size="18"></feather-icon>
                                        Connect Account</b-button>
                            </div>
                        </div>
                    </b-overlay>
                </div>
    </div>    
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import {BBadge, BButton, BFormGroup, BFormCheckbox, BProgress, BFormRadioGroup, BFormRadio, BOverlay,BAlert} from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'
import {user_details} from '@/apis/auth'
import {attach_payment_method, get_payment_method, delete_payment_method,} from '@/apis/others/subscriptions'
import { connect_bank_account, get_connected_account} from '@/apis/others/payments'
import { Country, State, City }  from 'country-state-city';
import { ValidationProvider, ValidationObserver} from 'vee-validate'
import { required} from '@validations'
import {getUserData} from '@/auth/utils'
import moment from 'moment'
export default {
    components: {
        AppHeading3,
        BBadge,
        BAlert,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        BButton,
        BFormGroup,
        BFormCheckbox,
        AppTextInput,
        AppSelectBox,
        BProgress,
        BFormRadioGroup,
        BFormRadio,
        AppLabel,
        BOverlay,
        ValidationProvider, 
        ValidationObserver
    },
    data(){
        return{
            show:false,
            style:{
                base: {
                iconColor: '#c4f0ff',
                color: '#646464',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#000'
                },
                '::placeholder': {
                    color: '#b9b9c3',
                    fontSize:'14px'
                },
                invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE'
                }
                },
            },
            subscription:{
                name: "Free",
                subscriptionStartDate: "",
                subscriptionEndDate: "",
            },
            sripCustomerId:'',
            billing_details:{
                country:'US',
                line1:'',
                line2:'',
                city:'',
                state:'',
                postal_code:'',
            },
            options: [],
            states: [],
            cities:[],
            cardName:'',
            stripeInstance:'',
            stripeElement:'',
            cardNumber:'',
            cardExpiry:'',
            cardCvc:'',
            error:'',
            loading:false,
            newPayment:true,
            cardData:{
                payment_method_id:'',
                last4:'',
                exp_month:'',
                exp_year:'',
                brand:'',
            },
            loading:false,
            update:false,
            bankAccounts:[],
            bankAccountLoading:false,
            required,
            errorMessage:'',
            cardNumberError:'',
            expError:'',
            cvcError:'',
        }
    },
    mounted(){
        this.getUserDetails()
        this.createStripeInstence()
        this.setAllCountries(Country.getAllCountries())
        this.getPaymentMethod()
        this.getConnectedAccount()
        this.setStates()
    },
    computed:{
        getUserRole(){
            return getUserData().role
        }
    },  
    methods: {
        getUserDetails(){
            user_details().then(resp=>{
               this.subscription=resp.data.subscriptionTier
                this.sripCustomerId=resp.data.stripeCustomerId
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        getDiffernce(startTime){
            let endTime = moment(new Date)
            var duration = moment.duration(endTime.diff(moment(startTime)));
            return duration.asDays().toFixed(0)
        },
        getPercentage(startTime){
            return (Number(this.getDiffernce(startTime))+1)/30*100
        },
        setAllCountries(countries) {
            this.options = countries.map(country => ({
                value: country.isoCode,
                text: country.name
            }))
            this.options.unshift({
                value: null,
                text: 'Country'
            })
        },
        setStates(){
            const states = State.getStatesOfCountry(this.billing_details.country)
            this.states = states.map(state=> ({
                value: state.isoCode,
                text: state.name
            }))
            this.states.unshift({
                value: '',
                text: 'State'
            })
        },
        setCity(){
            const cities = City.getCitiesOfState(this.billing_details.country,this.billing_details.state)
            this.cities = cities.map(city=> ({
                value: city.name,
                text: city.name
            }))
            this.cities.unshift({
                value: '',
                text: 'City'
            })
        },
        async createStripeInstence(){
            this.stripeInstance = await loadStripe(process.env.VUE_APP_STRIPE_SECRET_KEY);
            this.stripeElement = this.stripeInstance.elements();
            this.cardNumber = this.stripeElement.create('cardNumber', { style: this.style,})
            this.cardNumber.mount(this.$refs.cardNumber)
            this.cardExpiry = this.stripeElement.create('cardExpiry',{ style: this.style})
            this.cardExpiry.mount(this.$refs.expryDate)
            this.cardCvc = this.stripeElement.create('cardCvc',{ style: this.style})
            this.cardCvc.mount(this.$refs.cvc)
        },
        validatePaymentData(){
            this.setCardNumberError();
            this.setExpError();
            this.setCvcError();
            this.$refs.paymentForm.validate().then(success=>{
                if(!success || this.expError || this.cvcError || this.cardNumberError) {
                    window.scrollTo(0,0);
                    this.errorMessage = 'Please fill all the required fields & enter valid data'
                    return
                }
                this.createPayment()
            })
        },
        async createPayment(){
            this.loading=true;
            const { error, paymentMethod } = await this.stripeInstance.createPaymentMethod({
                type: 'card',
                card: this.cardNumber,
                billing_details: {
                    name:this.cardName,
                    address:this.billing_details
                }
            })
            if (error) {
                this.error=error.message
                this.loading=false;
            } else {
                this.attachPaymentMethod(paymentMethod.id)
                this.loading=false;
            }
        },
        attachPaymentMethod(id){
            let data={
                paymentMethodId: id,
                customerId:this.sripCustomerId
            }
            attach_payment_method(data).then(resp=>{
                this.$bvToast.toast('Payment Method Added', {
                        title: 'Success',
                        variant:'success',
                        solid: true,
                    }) 
                this.update=false;
               this.getPaymentMethod()
            })
        },
        getPaymentMethod(){
            this.loading=true;
            this.newPayment = true;
            get_payment_method().then(resp=>{
                this.loading=false;
                this.newPayment = false;
                this.cardData.payment_method_id=resp.id;
                this.cardData.last4=resp.card.last4;
                this.cardData.exp_month=resp.card.exp_month;
                this.cardData.exp_year=resp.card.exp_year;
                this.cardData.brand=resp.card.brand;
                this.billing_details=resp.billing_details.address;
            }).catch(err=>{
                this.loading=false;
            })
        },
        getBrandIcon(name){
            if(name=='visa'){
                return require('@/assets/images/app-icons/visa.png')
            }else if(name=='master'){
                return require('@/assets/images/app-icons/master.png')
            }else if(name=='american-express'){
                return require('@/assets/images/app-icons/american-express.png')
            }else{
                return require('@/assets/images/app-icons/credit-card.png')
            }
        },
        deletePaymentMethod(){
            delete_payment_method({paymentMethodId:this.cardData.payment_method_id}).then(
                resp=>{
                    this.$bvToast.toast(resp.message, {
                        title: 'Payment Method Removed',
                        variant: 'success',
                        solid: true,
                    })
                        this.billing_details={
                            country:'US',
                            line1:'',
                            line2:'',
                            city:'',
                            state:'',
                            postal_code:'',
                        }
                        this.cardName='' 
                        this.createStripeInstence() 
                        this.getPaymentMethod()
                        this.$refs.paymentForm.reset()

                }
            )
        },
        updatePaymentMethod(){
            this.update=true;
            this.newPayment=true;
        },
        connectBankAccount(){
            connect_bank_account().then(resp=>{
                window.open(resp.data.url,'_blank')
            })
        },
        getConnectedAccount(){
            this.bankAccountLoading=true;
            get_connected_account().then(resp=>{
                this.bankAccountLoading=false
                this.bankAccounts=resp.data.external_accounts.data
            }).catch(err=>{
                this.bankAccountLoading=false
            })
        },
        isZipCode(e){
            if(![8,9,37,39,46].includes(e.keyCode)){
                let char = String.fromCharCode(e.keyCode); // Get the character
                if(/[0-9]/.test(char)&& this.billing_details.postal_code.length<7) return true;
                // if(this.user_data.phone_number.length<11) return true; // Match with regex 
                else e.preventDefault(); 
            }
        },
        setExpError(){
            if(this.cardExpiry._empty){
                this.expError='Expiry date required'
            }else if(this.cardExpiry._invalid){
                this.expError='Invalid expiry date'
            }else{
                this.expError=''
            }
        },
        setCardNumberError(){
            if(this.cardNumber._empty){
                this.cardNumberError='Card number required'
            }else if(this.cardExpiry._invalid){
                this.cardNumberError='Invalid card number'
            }else{
                this.cardNumberError=''
            }
        },
        setCvcError(){
            if(this.cardCvc._empty){
                this.cvcError='CVC required'
            }else if(this.cardCvc._invalid){
                this.cvcError='Invalid CVC'
            }else{
                this.cvcError=''
            }
        },
        isLetter(e) {
            if(![8,9,37,39,46].includes(e.keyCode)){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[a-zA-Z ]*$/.test(char)) return true; // Match with regex 
            else e.preventDefault() ; // If not match, don't add to input text
            }
            
        },
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}

.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #4B465C;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #4B465C;
}
.text-small{
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #4B465C;
}
.mb-6{
    margin-bottom: 6px;
}
.input-field{
    border:1px solid #DBDADE;
    border-radius: 6px;
    padding: 9px 14px;
}
.del-btn:hover{
    opacity: .8;
}
.update-btn:hover{
    opacity: .8
}
.m-right-4{
    margin-right: 4px;
}
</style>