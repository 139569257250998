<template>
    <div class="p-2 h-100 w-100" v-if="show">
        <div class="d-flex justify-content-between align-items-center">
            <p class="sign-heading">Sign Document</p>
            <feather-icon class="cursor-pointer" icon="XIcon" size="18" @click="closeSignature"></feather-icon>
        </div>
        <div class="my-1 " >
            <div class="custom-fonts">
                <!-- <b-spinner variant="primary" v-show="loading" class="ml-1 align-middle"></b-spinner> -->
                <b-overlay :show="loading" spinner-variant="primary" class="w-100 h-100">
                <div class="signImg" >
                    <div class="my-5" ref="signatureDiv"  :style="{fontFamily: signatureData.fontStyle, color:signatureData.color, fontSize:`${uperCase<8?20:uperCase<16?15:10}px` }">
                        <span >{{signatureData.signatureText}}</span>
                    </div>
                    <!-- <b-overlay :show="loading" class="w-100 h-100">
                        <img :src="signature.imgUrl" alt="" @load="disabledBtn=false" class="w-100 h-100">
                    </b-overlay> -->
                </div>
                </b-overlay>
            </div>
            
            <div class="">
                    <b-button variant="primary" :disabled="loading" @click="convertToBase64()" class="mt-1">Sign</b-button>
                    <b-spinner variant="primary" v-if="signDocLoading" class="ml-1 align-middle"></b-spinner>
            </div>
           
        </div>
        <div class="signature-card p-1">
            <div class="small-heading mb-1">Signatures</div>
            <p class="small-text mb-1">Your Signatures are saved in Settings. You can change your signatures in Settings</p>
            <router-link :to="{path:'/settings',query:{tab:'signature'}}" class="small-text text-primary text-underline">Open Settings</router-link>
        </div> 

        <div class="mt-5">
            <b-button variant="primary"  :disabled="disableSubmitBtn" @click="submitSignature()">Submit</b-button>
        </div>  
   </div>
</template>
<script>
import {BButton,BOverlay, BSpinner} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue';
import {get_signature,get_typed_signature } from '@/apis/others/user'
export default {
    components:{
        BButton,
        AppSimpleButton,
        BOverlay,
        BSpinner
    },
    data(){
        return {
         show:false,
         signature:{
            imgUrl:'',
            imgType:''
         },
         disabledBtn:true,
         disableSubmitBtn:true,
         loading:false,
         signDocLoading:false,
         signatureData:{
                color: "",
                fontStyle: "",
                signatureText: ""
            },
            base64Image:null, 
            uperCase:0,
            displaySign:true,
        }
    },
    methods:{
        showSignature(){
            this.show=true;
            this.getSignature()
        },
        closeSignature(){
            this.show=false;
            this.$emit('close');
        },
        getSignature(){
            this.loading=true;
            this.displaySign=false;
            get_typed_signature().then(res=>{
                this.signatureData=res.data;
                this.uperCaseCount(res.data.signatureText)
                setTimeout(()=>{this.loading=false},3000)
            }).catch(err=>{
                this.loading=false;
                this.signatureUrl='';
            })
            // get_signature().then(res=>{
            //     this.loading=false;
            //     this.signature.imgUrl=res.data.url;
            //     this.signature.imgType=res.data.fileType;
            // }).catch(err=>{
            //     this.loading=false;
            // })
        },
        setLoader(){
            
        },
        convertToBase64() {
            const signatureDiv = this.$refs.signatureDiv;
            const width = signatureDiv.offsetWidth;
            const height = signatureDiv.offsetHeight;
            // Create a canvas
            const canvas = document.createElement("canvas");
            canvas.width = this.uperCase<8?600:this.uperCase<16?900:1200;
            canvas.height = 180;
            console.log('height & width:',width,height);

            const ctx = canvas.getContext("2d");

            // Set the canvas background color (optional)
            ctx.fillStyle = "transparent";
            ctx.fillRect(0, 0, width, height);

            // Use the DOM to render the div's content to the canvas
            const computedStyle = getComputedStyle(signatureDiv);
            ctx.font = `48px ${computedStyle.fontFamily}`;
            ctx.fillStyle = computedStyle.color;
            // ctx.textAlign = "center";
            // Get the text content of the div
            const text = signatureDiv.innerText || signatureDiv.textContent;
            ctx.textAlign = 'center'; // Align text horizontally to the center
            ctx.textBaseline = 'middle'; 
            // Draw the text onto the canvas
            ctx.fillText(text, this.uperCase<8?300:this.uperCase<16?450:600, 30);

            // Convert canvas to Base64 image
            this.base64Image = canvas.toDataURL("image/png");
            this.signDocument(this.base64Image)
        },
        signDocument(){
            this.disabledBtn=true;
            this.signDocLoading=true;
            this.$emit('sign',this.signatureData);
        },
        
        submitSignature(){
            this.disableSubmitBtn=true;
            this.$emit('submit');
        },
        documentSigned(){
            this.signDocLoading=false;
            this.disableSubmitBtn=false;
        },
        uperCaseCount(str){
            let uppercaseCount = 0;
            for (let char of str) {
                if (char >= 'A' && char <= 'Z') {
                    uppercaseCount++;
                }
            }
            this.uperCase=uppercaseCount;
        }
    }
}
</script>
<style scoped>
.sign-heading{
    font-weight: 500;
    size:16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0px;
}
.signature-card{
    border-radius: 6px;
    background-color: #f8fafc;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #000000;

}
.small-text{
    line-height: 15px;
    font-weight: 400;
    size:12px;
    color: #4B465C;
}
.text-underline{
    border-bottom: 1px solid #004bff;
    padding-bottom: 4px;
    
}
.signImg{
    border-radius: 6px;
    background-color: #f8fafc;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    height: 160px;
}
.submit-btn{
    position: absolute;
    bottom: 20px;
}
.saved-signature{
    border-radius: 6px;
    background-color: #f8fafc;
    padding: auto 10px;

    font-size: 8px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    width: 400px;
    height: 158px;
}
.custom-fonts{
    font-family: 'Aauto Signature','Brittany Signature','Motterdam Signature','Prestige Signature','Rich Jullietta Signature' !important; 
}
</style>