<template>
    <div>
        <b-modal ref="updateModal" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1>Edit User</app-heading-1>
                </div>
                <div class="mt-2">
                    <validation-observer ref="updateUserForm" >
                        <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                            <app-text-input label="Email Address" v-model="user_data.email" :disabled="true" placeholder="Enter your email address" :errors="errors" />
                        </validation-provider>
                        <div class="mb-1">
                            <validation-provider #default="{ errors }" name="Role" vid="role"  rules="required">
                                <app-label >Select Role</app-label>
                                <br>
                                <el-select
                                    class="w-100 "
                                    v-model="user_data.roles"
                                    multiple
                                    filterable
                                    tag-type="primary"
                                    allow-create
                                    placeholder="Select">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <br>
                                <small class="text-danger ">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                        
                        <!-- <validation-provider #default="{ errors }" name="Role" vid="role" rules="required">
                            <app-select-box label="Select Role" v-model="user_data.role" :options="options" :errors="errors"></app-select-box>
                        </validation-provider> -->
                        <app-text-input label="Company" v-model="user_data.companyName" placeholder="Enter" />
                    </validation-observer>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading"  @click="updateUser">Save Changes</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import AppLabel from "@/components/app-components/AppLabel"
import {update_user} from "@/apis/admin/users"
import { BModal, VBModal } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {Select,Option} from 'element-ui'
import Vue from 'vue'
Vue.use(Select)
Vue.use(Option)
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSelectBox,
        AppSimpleButton,
        BModal,
        VBModal,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            user_data:{
                email:'',
                companyName:'',
                roles:['client']
            },
            options: [
                { value: null, label: 'Select' },
                { value: 'client', label: 'Client' },
                { value: 'service_provider', label: 'Service Provider' },
            ],
            required, 
            email,
            loading:false,
            errorMessage: '',
            id:''
        }
    },
    methods:{
        showModal(data){
            this.$refs.updateModal.show()
            console.log('data:', data)
            this.user_data={
                email:data.email,
                roles:data.roles,
                companyName:data.companyName
            }
            this.id=data._id
        },
        hideModal(){
            this.show = false
            this.$refs.updateModal.hide()
        },
        updateUser(){
            this.errorMessage = '';
            this.$refs.updateUserForm.validate().then(success=>{
                if(!success){
                    this.errorMessage='Please fill all the required fields & enter valid data'
                    return
                }
                this.loading=true
                update_user(this.id,this.user_data).then(resp=>{
                    this.loading=false
                    this.$emit('onUpdated')
                    this.$bvToast.toast(resp.message, {
                        title: 'Success',
                        variant: 'success',
                        solid: true,
                    })
                    this.$refs.updateModal.hide()
                }).catch(err=>{
                    this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    })
                })
            })
        }
    },
}
</script>