<template>
    <div>
        <b-modal  ref="createSig" size="md" centered hide-header hide-footer hide-header-close no-close-on-backdrop>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Create Signatures</app-heading-1>
                    <app-normal-text>Type your signatures</app-normal-text>
                </div>
                <div class="my-3">
                    <div class="d-flex align-items-center my-2">
                        <app-label>Select Signature Color</app-label>
                        <div class="font-color mx-1" :class="[sigData.color==color?'active-color':'']" v-for="(color,index) in fontColors" :key="index" :style="{backgroundColor:color, outlineColor:color}" @click="()=>sigData.color=color"></div>
                    </div>
                    <div class="my-2">
                        <app-label>Select Font for Signature</app-label>
                        <select v-model="sigData.fontStyle" class="font-selector">
                            <option 
                              v-for="(font,index) in fonts" 
                              :value="font" 
                              :style="{ fontFamily: font }" :key="index">
                              {{ font }}
                            </option>
                          </select>
                    </div>
                    
                    
                    <div class="my-2">
                    <app-label>Type signatures</app-label>
                      <input type="text" maxlength="25" id="signinput" class="signature-input" v-model="sigData.signatureText" @keydown="isLetter" :style="{ fontFamily: sigData.fontStyle, color: sigData.color}">
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading"  @click="moveNext">Save</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import { BModal, VBModal,BDropdown, BDropdownItem } from 'bootstrap-vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import {create_signature, create_typed_signature} from "@/apis/others/user"
export default {
    components: {
        AppHeading1,
        AppSimpleButton,
        BModal,
        VBModal,
        AppNormalText,
        BDropdown, 
        BDropdownItem,
        AppLabel
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            loading:false,
            errorMessage: '',
            url:'',
            sigData:{
                signatureText:'',
                fontStyle:'Aauto Signature',
                color:'#000000',
            },
            fonts: [
            'Aauto Signature',
            'Brittany Signature',
            'Motterdam Signature',
            'Prestige Signature',
            'Rich Jullietta Signature'
            ],
            fontColors:[
                '#000000',
                '#6a6a77',
                '#004bff',
                '#d42d30'
            ],
    
        }
    },
    methods:{
        showModal(obj={signatureText:'',fontStyle:'Georgia',color:'#000000',},){
            this.sigData=obj;
            this.$refs.createSig.show()
            this.loading=false
        },
        hideModal(){
            this.$refs.createSig.hide()
        },
        addFile(e){
            if(['image/jpeg','image/png','image/jpg'].includes(e.type)){
                this.file=e;
                this.url=URL.createObjectURL(this.file)
            }else{
                this.$refs.myFileDropzone.removeFile(e);
                this.$bvToast.toast('Upload valid image file', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }

        },
        moveNext(){
            this.loading=true;
            create_typed_signature(this.sigData).then(resp=>{
                this.loading=false;
                this.$refs.createSig.hide()
                this.$emit('onCreated')
            });
        },
        isLetter(e) {
            if(![8,9,37,39,46,32].includes(e.keyCode)){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault() ; // If not match, don't add to input text
            }
            
        },
        
    },
}
</script>
<style scoped>
    .font-selector{
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        background-color: #F6F7F9;
        border: 1px solid #DBDADE;
    }
    .signature-input{
        width: 100%;
        padding: 10px;
        height: 160px;
        line-height: 2 !important; 
        font-family: 'Cursive'; 
        font-size: 32px;
        text-align:center !important;
        font-weight: bold;
        border-radius: 6px;
        background-color: #F6F7F9;
        border: 1px solid #DBDADE;
        box-sizing: border-box;
    }
    .signature-input:foucs{
        outline: none !important;
    }
    .font-color{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
    }
    .active-color{
        outline-width: 2px;
        outline-style: solid;
        outline-offset: 2px;
    }
    .custom-fonts{
        font-family: 'Aauto Signature','Brittany Signature','Motterdam Signature','Prestige Signature','Rich Jullietta Signature';
    }
</style>